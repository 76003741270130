import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import driverSignupImage from '../../../assets/img_driver_signup.jpeg';
import { useAppSelector } from 'redux/hook';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { constants } from 'utils/constants';
import routesConstants from 'utils/routesConstants';
import AuthenticationDialog from './AuthenticationDialog';
import googlePlayImage from '../../../assets/get_on_google_play.png';

const schema = yup.object().shape({
  name: yup.string().required('El nombre es obligatorio'),
  phone: yup
    .string()
    .phone('SV', 'Ingrese un número de teléfono válido')
    .max(9, 'Máximo 8 dígitos')
    .required('El número de teléfono es obligatorio'),
  email: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('El correo electrónico es obligatorio'),
  password: yup
    .string()
    .min(6, 'Mínimo 6 caracteres')
    .required('La contraseña es obligatoria'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden')
    .required('Confirme la contraseña'),
  sector: yup.string().required('El sector es obligatorio'),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], 'Debes aceptar los términos y condiciones')
    .required('Términos y condiciones son obligatorios'),
});
const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};
type FormData = {
  name: string;
  sector: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
};
export const DriverRegisterForm = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [registerDriverWithAccount, registerWithAccountResult] =
    chaletApi.useRegisterDriverWithChaletAccountMutation();
  const [registerDriverWithoutAccount, registerDriverWithoutAccountResult] =
    chaletApi.useRegisterDriverMutation();
  const [getAllProductTypes] = chaletApi.useLazyGetAllProductTypesQuery();
  const [loginWithToken] = chaletApi.useLazyLoginWithTokenQuery();
  const { data: sectors } = chaletApi.useGetSectorsQuery();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAppSelector((state) => state.user);
  const { uniqueId, deviceCart } = useAppSelector((state) => state.device);
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);

  const [shouldAuthenticate, setShouldAuthenticate] = useState(false);
  const [pendingData, setPendingData] = useState<FormData | null>(null);

  const isLoading = useMemo(() => {
    return (
      registerWithAccountResult.isLoading ||
      registerDriverWithoutAccountResult.isLoading
    );
  }, [registerWithAccountResult, registerDriverWithoutAccountResult]);

  useEffect(() => {
    getAllProductTypes();
  }, [getAllProductTypes]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      sector: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
      termsAndConditions: false,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user) {
      setValue('name', user.name || '');
      setValue('phone', MaskNumber(user.phone) || '');
      setValue('email', user.email || '');
    }
  }, [user, setValue]);

  const registerUnloggedUser = (data: FormData) => {
    const { name, email, phone, sector: sectorId, password } = data;
    registerDriverWithoutAccount({ name, email, phone, sectorId, password })
      .unwrap()
      .then((response) => {
        console.log(response);
        if (response.success) {
          handleRegisterSuccess();
        } else if (response.code === 'USER_ALREADY_EXISTS') {
          setShouldAuthenticate(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if ('data' in error && error.data.error) {
          toast.error(error.data.error);
        }
      });
  };

  const onSubmit = (data: FormData) => {
    const { email, phone, sector: sectorId, password } = data;
    setPendingData(data);
    if (isLoggedIn) {
      registerDriverWithAccount({ email, phone, sectorId, password })
        .unwrap()
        .then(
          () => {
            handleRegisterSuccess();
          },
          (error) => {
            if ('data' in error && error.data.error) {
              toast.error(error.data.error);
            }
          },
        );
    } else {
      registerUnloggedUser(data);
    }
  };

  const handleRegisterSuccess = () => {
    setPendingData(null);
    toast.success(
      'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo. Descarga la app de repartidor para comenzar a trabajar.',
    );
    navigate('/profile');
    setTimeout(() => {
      window.open(
        'https://play.google.com/store/apps/details?id=com.chalatech.driverapp&hl=es',
        '_blank',
      );
    }, 2000);
  };

  const onUserAuthenticated = (token: string) => {
    const { email, name, phone, sector: sectorId, password } = pendingData!;
    loginWithToken({ token, uniqueId, carts: deviceCart })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          registerDriverWithAccount({ name, phone, sectorId, password, email })
            .unwrap()
            .then(() => {
              handleRegisterSuccess();
            })
            .catch((e) => {
              toast.error(JSON.stringify(e));
            });
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.data.error));
      });
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // setValue('name', 'DriverTest1');
      // setValue('password', '123456');
      // setValue('confirmPassword', '123456');
      // setValue('email', 'drivertest1@gmail.com');
      // setValue('phone', '7532-5632');
      // setValue('sector', '1');
    }
  }, []);

  useEffect(() => {
    if (user && (user.driver || user.transportDriver)) {
      toast.success('Ya eres repartidor registrado');
      navigate('/profile');
    }
  }, [user]);

  let cardMessage =
    'Obtén acceso a la plataforma de última generación para realizar envíos y viajes e incrementar tus ingresos.';

  let cartTitle = '¡Regístrate ahora!';

  if (registerWithAccountResult.isSuccess) {
    cardMessage =
      'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo.';
    cartTitle = 'Solicitud enviada';
  }

  const openPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.chalatech.driverapp&hl=es',
      '_blank',
    );
  };

  return (
    <Grid
      container
      sx={{ backgroundColor: 'background.default', padding: isMobile ? 2 : 4 }}
      justifyContent="center"
    >
      <Grid
        item
        container
        spacing={isMobile ? 2 : 4}
        sx={{
          maxWidth: 'lg',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mb: isMobile ? 2 : 4 }}
          >
            <img
              src={driverSignupImage}
              width="100%"
              style={{
                objectFit: 'cover',
                maxWidth: '400px',
                borderRadius: '8px',
              }}
            />
          </Grid>
          <Paper sx={{ backgroundColor: 'primary.main', p: 2 }}>
            <Stack direction="column" spacing={1} alignItems="center">
              <Typography variant="h6" color="white.main">
                {cartTitle}
              </Typography>
              <Typography
                variant="body1"
                color="white.main"
                fontWeight="medium"
                textAlign="center"
              >
                {cardMessage}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper
            sx={{
              p: isMobile ? 2 : 4,
              borderRadius: 2,
              maxWidth: isMobile ? '100%' : 500,
              margin: '0 auto',
            }}
            elevation={4}
          >
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Registro
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Nombre"
                        fullWidth
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Número celular"
                        fullWidth
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                        onChange={(event) =>
                          field.onChange(MaskNumber(event.target.value))
                        }
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="sector"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <FormControl fullWidth error={!!error} sx={{ mb: 2 }}>
                        <InputLabel>Sector</InputLabel>
                        <Select {...field} label="Sector">
                          {sectors?.map((sector) => (
                            <MenuItem key={sector.id} value={sector.id}>
                              {sector.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {error && (
                          <Typography variant="caption" color="error">
                            {error.message}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Correo electrónico"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        disabled={user?.email && isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Contraseña"
                        fullWidth
                        type="password"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Confirmar contraseña"
                        type="password"
                        fullWidth
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                      />
                    )}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    textAlign="center"
                  >
                    Con esta contraseña podrás acceder a la app para recibir
                    pedidos o viajes.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="termsAndConditions"
                    control={control}
                    defaultValue={false}
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column">
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              color="primary"
                            />
                          }
                          label={
                            <span>
                              Acepto los{' '}
                              <Link
                                href={
                                  constants.BASE_URL +
                                  routesConstants.driverTermsAndConditions
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Términos y Condiciones
                              </Link>
                            </span>
                          }
                        />
                        {error && (
                          <Typography variant="caption" color="error">
                            {error.message}
                          </Typography>
                        )}
                      </Stack>
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                disabled={isLoading}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
                size="large"
              >
                Registrarme
              </Button>
            </Box>
          </Paper>
          <Grid
            xs={12}
            md={6}
            item
            container
            justifyContent={isMobile ? 'center' : 'end'}
            alignItems="center"
            direction="column"
          >
            <ButtonBase onClick={openPlayStore}>
              <img
                src={googlePlayImage}
                alt="Get it on Google Play"
                style={{ height: 95 }}
              />
            </ButtonBase>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: 1, textAlign: 'center' }}
            >
              Descarga la app de repartidor/chofer
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <AuthenticationDialog
        setShouldAuthenticate={setShouldAuthenticate}
        shouldAuthenticate={shouldAuthenticate}
        onAuthenticated={onUserAuthenticated}
        name={watch('name')}
        phone={watch('phone')}
      />
    </Grid>
  );
};
