import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import chaletApi from '../../../../api/chaletApi';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { VerificationCode } from '../verificationCode';
import { useAppSelector } from 'redux/hook';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone-lite';
import routesConstants from 'utils/routesConstants';
import { AnalyticsEvent, AnalyticsManager } from 'services/analytics';

const schema = yup.object().shape({
  phone: yup
    .string()
    .max(9, 'maximo 8 digitos')
    .phone('SV', 'el numero es invalido')
    .required('el numero es requerido'),
});

export const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};

type props = {
  handleShowRegister?: () => void;
};

export const Login: React.FC<props> = ({ handleShowRegister }) => {
  const [phone, setPhone] = useState<string>('');
  const [resendCode] = chaletApi.useLazyResendCodeQuery();
  const [showVerification, setShowVerification] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(routesConstants.home);
    }
  }, [isLoggedIn]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  type FormData = {
    phone: string;
  };

  const onSubmit = (data: FormData) => {
    const phone = data.phone;
    setPhone(phone);
    AnalyticsManager.trackEvent(AnalyticsEvent.CLICK_LOGIN_VERIFY_CODE, {
      phone: phone,
    });
    resendCode({ phone })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          setShowVerification(true);

          toast.success(payload.data);
        } else {
          toast.error(payload.error);
        }
      })
      .catch((error) => {
        console.error('rejected', error);
        toast.error(error);
      });
  };

  return (
    <Paper sx={{ padding: '20px', borderRadius: '10px' }} elevation={4}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h4">
        Iniciar sesión
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              disabled={showVerification}
              type="tel"
              inputMode="numeric"
              label="Teléfono"
              fullWidth
              sx={{ mb: 1.5 }}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              onChange={(event) => {
                const { value } = event.target;
                field.onChange(MaskNumber(value)); // Actualiza el valor del campo
              }}
              value={field.value} // Asigna el valor del campo
            />
          )}
        />

        {!showVerification && (
          <Button fullWidth type="submit" variant="contained" sx={{ mb: 3 }}>
            Iniciar sesión
          </Button>
        )}

        {showVerification && <VerificationCode phone={phone} />}

        <Stack
          direction={'row'}
          spacing={0.5}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography>¿No tienes una cuenta?</Typography>
          {handleShowRegister ? (
            <Typography
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              color={'primary'}
              onClick={handleShowRegister}
            >
              Registrate
            </Typography>
          ) : (
            <Typography
              color="primary"
              component={Link}
              to={routesConstants.signUp}
            >
              Registrate
            </Typography>
          )}
        </Stack>
      </Box>
    </Paper>
  );
};
