import { createSlice } from '@reduxjs/toolkit';
import chaletApi from '../../api/chaletApi';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AnalyticsManager } from 'services/analytics';

type UserAuthState = {
  isLoggedIn: boolean;
  userId: number | null;
  token: string | null;
  userCart: { branchId: number; id: number }[];
};

const initialState: UserAuthState = {
  isLoggedIn: false,
  userId: null,
  token: null,
  userCart: [],
};

const userAuthPersistConfig = {
  key: 'userAuth',
  storage: storage,
};

export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    logOut: (state) => {
      state.userId = null;
      state.token = null;
      state.isLoggedIn = false;
      state.userCart = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chaletApi.endpoints.loginWithToken.matchFulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.isLoggedIn = true;
          state.token = payload.data.token;
          state.userId = payload.data.user.id;

          AnalyticsManager.identifyUser(
            String(payload.data.user.id),
            payload.data.user.name,
          );
        }
      },
    );
    builder.addMatcher(
      chaletApi.endpoints.getCartByUser.matchFulfilled,
      (state, { payload }) => {
        if (payload.userCart) {
          const { branchId, id } = payload.userCart;
          if (
            !state.userCart.some(
              (item) => item.branchId === branchId && item.id === id,
            )
          ) {
            state.userCart.push({ branchId, id });
          }
        }
      },
    );
  },
});

const persistedUserAuthSlice = persistReducer(
  userAuthPersistConfig,
  userAuthSlice.reducer,
);

export const { logOut } = userAuthSlice.actions;

export default persistedUserAuthSlice;
