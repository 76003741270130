import { Container, Grid } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const Auth = () => {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '90vh' }}
      >
        <Grid item sx={{ width: 350 }}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};
