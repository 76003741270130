import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LocationsModal } from '../../components/partials/Locations/LocationsModal';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hook';
import foodLeft from '../../../assets/food-left.webp';
import foodRight from '../../../assets/food-right.webp';
import { ReactComponent as ElchaletLogo } from '../../../assets/ic_logo_no_title.svg';
import routesConstants from 'utils/routesConstants';
import { OptionCard } from './components/OptionCard';
import { DeliveryDining, Storefront } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { AnalyticsEvent, AnalyticsManager } from 'services/analytics';

export const Home = () => {
  const location = useAppSelector((state) => state.locationSlice);
  const [locationModal, setLocationModal] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);

  useEffect(() => {
    AnalyticsManager.trackEvent(AnalyticsEvent.VIEW_LANDING_PAGE);
  }, []);

  useEffect(() => {
    if (
      isLoggedIn ||
      (location && Object.values(location).every((value) => value !== null))
    ) {
      navigate(routesConstants.home, { replace: true });
    }
  }, [location, isLoggedIn]);

  const handleLocationModal = () => setLocationModal(false);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDriverApp = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.chalatech.driverapp',
      '_blank',
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#ef3d32',
          zIndex: 1,
          minHeight: '80vh',
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            display: 'flex',
            height: '100%',
            left: 0,
            right: 0,
            top: 0,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: -1,
          }}
        >
          <Box>
            <img
              src={foodLeft}
              height={'auto'}
              style={{
                width: isMedium ? 300 : 400,
                transform: isMedium
                  ? 'translate(-180px, -50px)'
                  : 'translate(-120px, -90px)',
              }}
            />
          </Box>

          <Box>
            <img
              src={foodRight}
              height={'auto'}
              style={{
                width: isMedium ? 300 : 400,
                transform: isMedium
                  ? 'translate(-10px, -50px)'
                  : 'translate(90px,-50px)',
              }}
            />
          </Box>
        </Box>
        <Stack
          direction="column"
          justifyContent={'center'}
          alignItems={'center'}
          spacing={2}
        >
          <Box>
            <ElchaletLogo width={200} height={100} />
            <Typography
              variant="h3"
              color="background.paper"
              sx={{ fontWeight: 'bold', mb: 2, p: 1 }}
              textAlign={'center'}
            >
              El Chalet
            </Typography>
          </Box>
          <Typography
            color="white.main"
            fontWeight={700}
            variant="h4"
            textAlign={'center'}
            ml={8}
            mr={8}
            gutterBottom
          >
            Compra en comercios locales <br /> con un click
          </Typography>
          <Button
            size="large"
            sx={{ width: isMobile ? '80%' : 300 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              AnalyticsManager.trackEvent(AnalyticsEvent.CLICK_LANDING_EXPLORE);
              setLocationModal(true);
            }}
          >
            Buscar comercios cercanos
          </Button>
          <Stack direction={'column'} spacing={1} alignItems={'center'}>
            <Typography color={theme.palette.common.white}>
              ¿Tienes una cuenta?
            </Typography>
            <Stack direction={'row'} spacing={1}>
              <Link
                href={routesConstants.login}
                fontWeight={'bold'}
                color={theme.palette.common.white}
                onClick={() =>
                  AnalyticsManager.trackEvent(
                    AnalyticsEvent.CLICK_LANDING_SIGN_IN,
                  )
                }
              >
                Inicia Sesión
              </Link>
              <Typography color={theme.palette.common.white}>o</Typography>
              <Link
                href={routesConstants.signUp}
                fontWeight={'bold'}
                color={theme.palette.common.white}
                onClick={() =>
                  AnalyticsManager.trackEvent(
                    AnalyticsEvent.CLICK_LANDING_SIGN_IN,
                  )
                }
              >
                Registrate
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: grey[200],
          zIndex: 1,
          minHeight: '60vh',
          pb: 10,
        }}
      >
        <Container>
          <Box mb={4} p={2}>
            <Typography
              variant="h3"
              color="primary"
              fontWeight={'bold'}
              mb={2}
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
              }}
            >
              Únete a nuestro equipo
            </Typography>
            <Typography sx={{ flexWrap: 'wrap' }}>
              Impulsa tu negocio o genera ingresos como repartidor. Ya sea que
              busques aumentar tus ventas o repartir pedidos con flexibilidad,
              nuestra plataforma te conecta con más oportunidades. ¡Únete hoy!
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 3,
            }}
          >
            <OptionCard
              Icon={Storefront}
              name="Registra tu negocio"
              source="/registrar-comercio"
              description="Recibe pedidos fácilmente, aumenta tus ventas y llega a más clientes.
               Administra tu inventario y operaciones en un solo lugar,
                con herramientas que mejoran tu servicio y
                 soporte dedicado para ayudarte a crecer."
            />
            <OptionCard
              onClick={handleOpenDriverApp}
              Icon={DeliveryDining}
              name="Registrate como repartidor"
              source="/"
              description="Únete a nuestro equipo y genera ingresos mientras decides tu propio horario.
               Pronto podrás repartir pedidos en tu área y ayudar a conectar negocios con sus clientes 
               de manera rápida y eficiente."
            />
          </Box>

          <LocationsModal
            open={locationModal}
            handleClose={handleLocationModal}
          />
        </Container>
      </Box>
    </>
  );
};
