import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';
type Props = {
  setShouldAuthenticate: (value: boolean) => void;
  shouldAuthenticate: boolean;
  name: string;
  phone: string;
  onAuthenticated: (token: string) => void;
};

const AuthenticationDialog = ({
  shouldAuthenticate,
  setShouldAuthenticate,
  phone,
  name,
  onAuthenticated,
}: Props) => {
  const [code, setCode] = useState('');
  const [resendVerificationCode] = chaletApi.useLazyResendCodeQuery();
  const [verifyCode] = chaletApi.useLazyVerifyCodeQuery();

  useEffect(() => {
    if (shouldAuthenticate) {
      resendVerificationCode({ phone });
    } else {
      setCode('');
    }
  }, [shouldAuthenticate]);

  const handleVerifyCode = () => {
    verifyCode({ phone, code })
      .then((response) => {
        if (response.data.success === true) {
          onAuthenticated(response.data.data.token);
          toast.success(
            'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo.',
          );
        } else {
          toast.error(
            'Código de verificación incorrecto. \nPor favor, intente de nuevo.',
          );
        }
      })
      .catch((e) => {
        toast.error(e.data.error);
      });
  };

  return (
    <Dialog
      open={shouldAuthenticate}
      onClose={() => setShouldAuthenticate(false)}
    >
      <DialogTitle>Autenticación requerida</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <DialogContentText>
            Para continuar, por favor ingrese el código de verificación que
            hemos enviado a su número de teléfono.
          </DialogContentText>
          <TextField
            name="name"
            label="Nombre"
            value={name}
            fullWidth
            disabled
          />
          <TextField
            name="phone"
            label="Teléfono"
            value={phone}
            fullWidth
            disabled
          />
          <TextField
            name="code"
            label="Código de verificación"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShouldAuthenticate(false)} color="primary">
          Cerrar
        </Button>
        <Box display="flex" flex={1} />
        <Button
          disabled={!code || isEmpty(code)}
          onClick={handleVerifyCode}
          variant="contained"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthenticationDialog;
