import React from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const DriverTermsAndConditions: React.FC = () => {
  return (
    <Paper sx={{ p: 3, maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Acuerdo de uso y protocolos - El Chalet
      </Typography>
      <Typography variant="body1" gutterBottom>
        Al utilizar la aplicación "El Chalet" como repartidor o chofer, acepto
        los siguientes acuerdos y protocolos:
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Para el Repartidor:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Respeto y trato amable:"
              secondary="Me comprometo a mantener un trato respetuoso y amable con los clientes en todo momento."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Relación como socio:"
              secondary='Mi relación con "El Chalet" es una alianza en calidad de socio. Tengo libertad de elegir mis horarios de trabajo mediante turnos que puedo programar previamente.'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Disponibilidad y gestión de órdenes:"
              secondary="Me comprometo a realizar entregas durante los horarios que yo mismo establezca. Durante mi turno, puedo pausar momentáneamente mi disponibilidad cuando no pueda aceptar nuevos pedidos."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Aceptación y cumplimiento de pedidos:"
              secondary="Al aceptar una oferta de entrega, recibiré claramente la información sobre el monto total a ganar por completar dicha orden. Una vez aceptada, me obligo a recoger los productos del restaurante o comercio y entregarlos correctamente en cada destino indicado."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Pedidos no recibidos por el cliente:"
              secondary="Si un cliente no recibe la orden, me comprometo a retornar los productos al restaurante o comercio de origen."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Billetera virtual:"
              secondary="La plataforma me proporcionará una billetera personal en la cual se reflejarán mis ganancias por pedidos realizados en línea. En caso de pagos en efectivo, la plataforma deducirá automáticamente la comisión correspondiente por el servicio prestado."
            />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Para el Driver / Chofer:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Respeto y ambiente agradable:"
              secondary="Me comprometo a ofrecer siempre un trato respetuoso y mantener un ambiente agradable y confortable para los clientes durante los viajes."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Aceptación de viajes:"
              secondary="Al recibir una oferta de viaje, tendré información clara sobre los ingresos que obtendré al completarla. Al aceptar, me comprometo a realizar el viaje completo según lo solicitado."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Gestión de pagos:"
              secondary="Mi billetera virtual registrará el saldo ganado por viajes realizados con pago en línea. En caso de pagos en efectivo, la plataforma descontará la comisión correspondiente automáticamente."
            />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Protocolos para casos controlados y externos:
        </Typography>
        <List>
          <ListItem>
            <ListItemText secondary="En caso de incidencias como accidentes, emergencias o problemas con la orden o viaje, me comprometo a informar inmediatamente a 'El Chalet' a través de los canales establecidos." />
          </ListItem>
          <ListItem>
            <ListItemText secondary="La plataforma 'El Chalet' se reserva el derecho a revisar y evaluar cualquier incidente reportado, tomando medidas necesarias para garantizar la seguridad, integridad y calidad del servicio ofrecido." />
          </ListItem>
          <ListItem>
            <ListItemText secondary="Se espera cooperación activa y comunicación clara entre socios (repartidores/drivers) y la plataforma para resolver rápida y eficazmente cualquier eventualidad." />
          </ListItem>
        </List>
      </Box>

      <Typography variant="body1" sx={{ mt: 3 }}>
        Al utilizar la aplicación, declaro haber leído, comprendido y aceptado
        estos acuerdos y protocolos.
      </Typography>
    </Paper>
  );
};

export default DriverTermsAndConditions;
