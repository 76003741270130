import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import shopImage from '../../../assets/img-shop-bike.webp';
import { useAppSelector } from 'redux/hook';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticationDialog from './AuthenticationDialog';
import { AnalyticsEvent, AnalyticsManager } from 'services/analytics';
import routesConstants from 'utils/routesConstants';
const schema = yup.object().shape({
  name: yup.string().required('El nombre del negocio es obligatorio'),
  address: yup.string().required('La dirección del negocio es obligatoria'),
  type: yup.string().required('El tipo de negocio es obligatorio'),
  userName: yup.string().required('El nombre es obligatorio'),
  userPhone: yup
    .string()
    .phone('SV', 'Ingrese un número de teléfono válido')
    .max(9, 'Máximo 8 dígitos')
    .required('El número de teléfono es obligatorio'),
  userEmail: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('El correo electrónico es obligatorio'),
  password: yup
    .string()
    .min(6, 'Mínimo 6 caracteres')
    .required('La contraseña es obligatoria'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden')
    .required('Confirme la contraseña'),
  sector: yup.string().required('El sector es obligatorio'),
});
const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};
type FormData = {
  name: string;
  address: string;
  type: string;
  userName: string;
  sector: string;
  userPhone: string;
  userEmail: string;
  password: string;
  confirmPassword: string;
};
export const ShopRegisterForm = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [registerShopWithUser, registerResult] =
    chaletApi.useRegisterShopWithUserMutation();
  const [getAllProductTypes, result] =
    chaletApi.useLazyGetAllProductTypesQuery();
  const [registerUser] = chaletApi.useLazyUserRegisterQuery();
  const [loginWithToken] = chaletApi.useLazyLoginWithTokenQuery();
  const { data: sectors } = chaletApi.useGetSectorsQuery();
  const [createAddress] = chaletApi.useSaveUserAddressMutation();
  const [updateLastLocationId] = chaletApi.useUpdateLastLocationIdMutation();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useAppSelector((state) => state.locationSlice);
  const { user } = useAppSelector((state) => state.user);
  const { uniqueId, deviceCart } = useAppSelector((state) => state.device);
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);

  const [shouldAuthenticate, setShouldAuthenticate] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [pendingData, setPendingData] = useState<FormData | null>(null);

  useEffect(() => {
    getAllProductTypes();
  }, [getAllProductTypes]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      address: '',
      type: '',
      userName: '',
      sector: '',
      userPhone: '',
      userEmail: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user) {
      setValue('userName', user.name || '');
      setValue('userPhone', MaskNumber(user.phone) || '');
      setValue('userEmail', user.email || '');
    }
  }, [user, setValue]);

  const onSubmit = (data: FormData) => {
    const {
      name,
      address,
      userEmail,
      userName,
      userPhone,
      type,
      sector: sectorId,
      password,
    } = data;
    const user = {
      name: userName,
      phone: userPhone,
      email: userEmail,
      password,
    };
    const shop = { name, address, productTypeId: parseInt(type), sectorId };
    registerShopWithUser({ user, shop })
      .unwrap()
      .then(
        () => {
          setIsRegistering(false);
          setPendingData(null);
          window.open(process.env.REACT_APP_SHOP_ADMIN_URL, '_blank');
          toast.success(
            'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo.',
          );
          reset();
        },
        (error) => {
          setPendingData(data);
          if ('data' in error && error.data.error) {
            if (error.data.code === 'USER_NOT_FOUND') {
              setIsRegistering(true);
              registerUser({ phone: userPhone, name: userName })
                .then(() => {
                  toast.success(
                    'Usuario registrado con éxito. Enviamos un código de verificación a tu número de teléfono.',
                  );
                  setShouldAuthenticate(true);
                })
                .catch((e) => {
                  toast.error(e.data.error);
                });
            } else if (error.data.code === 'USER_UNAUTHENTICATED') {
              setIsRegistering(false);
              setShouldAuthenticate(true);
            } else {
              toast.error(error.data.error);
            }
          }
        },
      );
  };

  const onUserAuthenticated = (token: string) => {
    loginWithToken({ token, uniqueId, carts: deviceCart })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          if (isRegistering === true) {
            if (location) {
              const addressData = {
                name: location.name,
                address: location.address,
                reference: location.reference,
                latitude: location.location?.coordinates[1],
                longitude: location.location?.coordinates[0],
              };
              createAddress(addressData)
                .unwrap()
                .then((payload) => {
                  updateLastLocationId(payload.data.id);
                });
            }
          }

          const {
            name,
            address,
            userEmail,
            userName,
            userPhone,
            type,
            sector: sectorId,
            password,
          } = pendingData!;
          const user = {
            name: userName,
            phone: userPhone,
            email: userEmail,
            password,
          };
          const shop = {
            name,
            address,
            productTypeId: parseInt(type),
            sectorId,
          };

          registerShopWithUser({ user, shop })
            .unwrap()
            .then(() => {
              window.open(process.env.REACT_APP_SHOP_ADMIN_URL, '_blank');
              toast.success(
                'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo.',
              );
              reset();
              setPendingData(null);
              setIsRegistering(false);
              navigate(routesConstants.initial);
            })
            .catch((error) => {
              toast.error(
                'Error al reintentar el registro: ' + error.data?.error ||
                  error,
              );
            });

          toast.success('has iniciado sesión correctamente');
          navigate(routesConstants.initial);
        } else {
          AnalyticsManager.trackEvent(AnalyticsEvent.LOGIN_WITH_TOKEN_FAILED);
          toast.error('Ups! ha ocurrido un error, intentalo de nuevo');
        }
      })
      .catch((error) => {
        AnalyticsManager.trackEvent(AnalyticsEvent.LOGIN_WITH_TOKEN_FAILED);
        toast.error(`Ha ocurrido un error: ${error}`);
      });
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // setValue('name', 'Negocio de Prueba');
      // setValue('address', 'Dirección de Prueba');
      // setValue('userName', 'Admin');
      // setValue('password', '123456');
      // setValue('confirmPassword', '123456');
      // setValue('userEmail', 'testigu@gmail.com');
      // setValue('type', '89');
      // setValue('userPhone', '7777-7777');
      // setValue('sector', '1');
    }
  }, []);

  useEffect(() => {
    if (user && user.Shops && user.Shops.length > 0) {
      toast.success('Ya tienes un negocio registrado');
      navigate('/profile');
    }
  }, [user]);

  let cardMessage =
    'Obtén acceso a la plataforma de última generación para administrar tu negocio, incrementar tus ventas y llegar a más clientes.';

  let cartTitle = '¡Regístrate ahora!';

  if (registerResult.isSuccess) {
    cardMessage =
      'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo.';
    cartTitle = 'Solicitud enviada';
  }

  return (
    <Grid
      container
      sx={{ backgroundColor: 'background.default', padding: isMobile ? 2 : 4 }}
      justifyContent="center"
    >
      <Grid
        item
        container
        spacing={isMobile ? 2 : 4}
        sx={{
          maxWidth: 'lg',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mb: isMobile ? 2 : 4 }}
          >
            <img
              src={shopImage}
              width="100%"
              style={{
                objectFit: 'cover',
                maxWidth: '400px',
                borderRadius: '8px',
              }}
            />
          </Grid>
          <Paper sx={{ backgroundColor: 'primary.main', p: 2, m: 2 }}>
            <Typography variant="h6" color="white.main">
              {cartTitle}
            </Typography>
            <Typography variant="body1" color="white.main">
              {cardMessage}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper
            sx={{
              p: isMobile ? 2 : 4,
              borderRadius: 2,
              maxWidth: isMobile ? '100%' : 500,
              margin: '0 auto',
            }}
            elevation={4}
          >
            <Typography sx={{ mt: 1, mb: 3 }} variant="h4" textAlign="center">
              Registro de Negocio
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nombre del Negocio"
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
              <Controller
                name="sector"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error} sx={{ mb: 2 }}>
                    <InputLabel>Sector</InputLabel>
                    <Select {...field} label="Sector">
                      {sectors?.map((sector) => (
                        <MenuItem key={sector.id} value={sector.id}>
                          {sector.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && (
                      <Typography variant="caption" color="error">
                        {error.message}
                      </Typography>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Dirección del Negocio"
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <RadioGroup
                      name={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      sx={{ mb: 2, flexDirection: 'row', flexWrap: 'wrap' }}
                    >
                      {result.data?.map((productType) => (
                        <FormControlLabel
                          key={productType.id}
                          value={productType.id}
                          control={<Radio />}
                          label={productType.name}
                        />
                      ))}
                    </RadioGroup>
                  </>
                )}
              />
              {errors.type && (
                <Typography variant="body2" color="error">
                  {errors.type.message}
                </Typography>
              )}
              <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Administrador de comercio
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="userName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Nombre"
                        fullWidth
                        error={!!errors.userName}
                        helperText={errors.userName?.message}
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="userPhone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Número celular"
                        fullWidth
                        error={!!errors.userPhone}
                        helperText={errors.userPhone?.message}
                        onChange={(event) =>
                          field.onChange(MaskNumber(event.target.value))
                        }
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="userEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Correo electrónico"
                        fullWidth
                        error={!!errors.userEmail}
                        helperText={errors.userEmail?.message}
                        disabled={user?.email && isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Contraseña"
                        fullWidth
                        type="password"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Confirmar contraseña"
                        type="password"
                        fullWidth
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                      />
                    )}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    textAlign="center"
                  >
                    Con esta contraseña podrás acceder al portal de
                    administración de tu negocio.
                  </Typography>
                </Grid>
              </Grid>
              <Button
                disabled={registerResult.isLoading}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
                size="large"
              >
                Registrar Negocio
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <AuthenticationDialog
        setShouldAuthenticate={setShouldAuthenticate}
        shouldAuthenticate={shouldAuthenticate}
        onAuthenticated={onUserAuthenticated}
        name={watch('userName')}
        phone={watch('userPhone')}
      />
    </Grid>
  );
};
