import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  Icon: React.ElementType;
  name: string;
  source: string;
  description: string;
  onClick?: () => void;
}

export const OptionCard = ({
  Icon,
  name,
  source,
  description,
  onClick,
}: Props) => {
  const navigate = useNavigate();
  const redirect = (route: string) => {
    if (onClick) {
      onClick();
    } else {
      navigate(route);
    }
  };

  return (
    <Card
      onClick={() => redirect(source)}
      elevation={2}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: { md: 500, sm: 400, xs: 300 },
        height: { md: 300, sm: 300, xs: 300 },
        borderRadius: '20px',
        cursor: 'pointer',
        transition: 'transform 0.3s ease, background-color 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          backgroundColor: 'rgba(239, 61, 50, 0.1)',
        },
      }}
    >
      <Stack p={3}>
        <Stack direction={'row'} alignItems={'center'} spacing={1} mb={3}>
          <Icon color="primary" sx={{ fontSize: { sm: 45, xs: 20 } }} />
          <Typography
            sx={{ fontSize: { md: 30, sm: 24, xs: 18 } }}
            fontWeight={'bold'}
            color="primary.main"
          >
            {name}
          </Typography>
        </Stack>

        <Typography variant="body1">{description}</Typography>
      </Stack>
    </Card>
  );
};
