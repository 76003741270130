import { LocationOn, Warning } from '@mui/icons-material';
import { Box, Button, Container, Typography } from '@mui/material';
import { useState } from 'react';
import { AnalyticsEvent, AnalyticsManager } from 'services/analytics';
import { LocationsModal } from 'ui/components/partials/Locations';

const NoLocation = () => {
  const [locationModal, setLocationModal] = useState(false);
  const handleLocationModal = () => setLocationModal(false);
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        textAlign: 'center',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Warning color="error" sx={{ fontSize: 80 }} />
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Sin ubicación
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        Por favor, Ingresa una ubicación para encontrar tiendas cercanas.
      </Typography>
      <Button
        variant="outlined"
        disableElevation
        startIcon={<LocationOn />}
        onClick={() => {
          setLocationModal(true);
          AnalyticsManager.trackEvent(
            AnalyticsEvent.CLICK_CURRENT_ADDRESS_PAGE,
          );
        }}
      >
        Ingresar ubicación
      </Button>
      <LocationsModal open={locationModal} handleClose={handleLocationModal} />
    </Container>
  );
};

export default NoLocation;
