import mixpanel from 'mixpanel-browser';

if (process.env.REACT_APP_MIXPANEL_KEY) {
  console.log('Mixpanel key is defined. Analytics will be initialized.');
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
    debug: true,
  });
} else {
  console.warn(
    'Mixpanel key is not defined. Analytics will not be initialized.',
  );
}

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
console.log(process.env.REACT_APP_ENVIRONMENT, isDev);

// Definition of analytical events
export enum AnalyticsEvent {
  VIEW_LANDING_PAGE = 'view landing page', //
  CLICK_LANDING_SIGN_IN = 'click landing sign in', //
  CLICK_LANDING_EXPLORE = 'click landing explore', //
  VIEW_ORDERS_PAGE = 'view orders page', //
  VIEW_ORDER_DETAIL_PAGE = 'view order details page', //
  CLICK_CANCEL_ORDER = 'click cancel order', //
  CLICK_CONFIRM_CANCEL_ORDER = 'click confirm cancel order', //
  CLICK_SIGN_UP_RESEND_VERIFICATION_CODE = 'click sign up resend verification code', //
  VIEW_SIGN_UP = 'sign up initiated', //
  CLICK_SIGN_UP = 'click sign up', //
  SIGN_UP_FAILED = 'sign up failed', //
  SIGN_UP_SUCCESS = 'sign up success', //
  VERIFY_SUCCESS = 'verify success', //
  VERIFY_FAILED = 'verify failed', //
  LOGIN_WITH_TOKEN_SUCCESS = 'login token success', //
  LOGIN_WITH_TOKEN_FAILED = 'login token failed', //
  CLICK_LOGIN_VERIFY_CODE = 'click login verify code', //
  LOGIN_VERIFY_SUCCESS = 'login verify success', //
  LOGIN_VERIFY_FAILED = 'login verify failed', //
  CLICK_MENU_CATEGORY = 'click menu category', //Without reference
  VIEW_PRODUCT_PAGE = 'view product page', //
  CLICK_ADD_TO_CART = 'click add to cart', //
  VIEW_USER_CART_PAGE = 'view user cart page', //
  VIEW_CONFIRM_ORDER_PAGE = 'view confirm order page', //
  CLICK_CART_DECREMENT_PRODUCT = 'click cart decrement product', //
  CLICK_CART_INCREMENT_PRODUCT = 'click cart increment product', //
  CLICK_CART_DELETE_PRODUCT = 'click cart delete product', //
  CLICK_CONFIRM_ORDER = 'click confirm order', //
  ORDER_CONFIRMED_SUCCESS = 'order confirmed success', //
  ORDER_CONFIRMED_FAILED = 'order confirmed failed', //
  VIEW_USER_PROFILE_PAGE = 'view user profile page', //
  CLICK_MY_ACCOUNT = 'click my account', //this button does not exist
  CLICK_SUPPORT = 'click support', //
  CLICK_FAQ = 'click faq', //
  CLICK_ABOUT_US = 'click about us', //
  LOGOUT = 'log out', //
  VIEW_HOME_PAGE = 'view home page', //
  CLICK_SERVICES_PAGE = 'click services page', //PENDING
  VIEW_PRODUCT_TYPE_BRANCHES_PAGE = 'view product type branches page',
  CLICK_POPULAR_BRANCH = 'click popular branch', //
  CLICK_BRANCH = 'click branch', //
  CLICK_CURRENT_ADDRESS_PAGE = 'click current address page', //
  SELECT_CURRENT_ADDRESS = 'select current address', //
  CLICK_ADD_USER_ADDRESS = 'click add user address', //
  VIEW_LOCATION_SELECTION_PAGE = 'click location selection page', //
  LOCATION_SELECTION_SELECT_PREDICTION = 'location selection select prediction', //
  LOCATION_SELECTION_CONTINUE = 'location selection continue',
  CHAT_OPEN = 'order chat open', //pending
  SEND_MESSAGE = 'send order chat message', //pending
  CONTACT_ORDER_SUPPORT = 'contact order support', //pending
}

// Types for events of events
export type AnalyticsProperties = Record<string, any>;

// Main class for Mixpanel management
export class AnalyticsManager {
  static trackEvent(
    event: AnalyticsEvent,
    properties: AnalyticsProperties = {},
  ): void {
    if (isDev) {
      console.log(`Debug: Tracking event ${event} with properties`, properties);
      return;
    }
    mixpanel.track(event, properties);
  }

  static identifyUser(userId: string, name: string): void {
    if (isDev) {
      console.log(`Debug: Identifying user ${userId} with name ${name}`);
      return;
    }
    mixpanel.identify(userId);
    mixpanel.people.set({
      name: name,
    });
  }

  /*  static flush(): void {
    if (isDev) {
      console.log('Debug: Flushing analytics');
      return;
    }
    mixpanel.flush();
  } */

  static reset(): void {
    if (isDev) {
      console.log('Debug: Resetting analytics');
      return;
    }
    mixpanel.reset();
  }
}
