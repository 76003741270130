import React, { useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Card,
  Stack,
  MenuItem,
  ListItemIcon,
  ListItemText,
  MenuList,
} from '@mui/material';
import ElSalvadorLogo from '../../../assets/El_Salvador_flag.png';
import { Link } from 'react-router-dom';
import chaletApi from '../../../api/chaletApi';
import { ReactComponent as ElchaletLogo } from '../../../assets/ic_logo_no_title.svg';
import { logOut } from '../../../redux//slices/userAuthSlice';
import { clearLocation } from 'redux/slices/locationSlice';
import { clearAllCarts } from 'redux/slices/cartSlice';
import {
  Help,
  KeyboardArrowRight,
  People,
  SupportAgent,
  Build,
} from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { SingIn } from 'ui/components/common/SingIn';
import EditIcon from '@mui/icons-material/Edit';
import routesConstants from 'utils/routesConstants';
import { AnalyticsEvent, AnalyticsManager } from 'services/analytics';

export const Profile = () => {
  const { userId } = useAppSelector((state) => state.userAuthSlice);
  const [getUserById, result] = chaletApi.useLazyGetUserByIdQuery();
  const dispatch = useAppDispatch();

  const LogOut = async () => {
    try {
      AnalyticsManager.trackEvent(AnalyticsEvent.LOGOUT);
      await dispatch(logOut());
      await dispatch(clearLocation());
      await dispatch(clearAllCarts());
      window.location.href = routesConstants.login;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    AnalyticsManager.trackEvent(AnalyticsEvent.VIEW_USER_PROFILE_PAGE);
  }, []);

  useEffect(() => {
    if (userId) {
      getUserById(userId);
    }
  }, [userId]);

  if (result.isLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  const handleOpenShopPortal = () => {
    window.open(process.env.REACT_APP_SHOP_ADMIN_URL, '_blank');
  };

  if (result.data) {
    const user = result.data;
    const hasShop = user.Shops.length > 0;
    const ShopButton = () => {
      if (hasShop) {
        const shop = user.Shops[0];
        return (
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={handleOpenShopPortal}
            sx={{ marginBottom: 1 }}
          >
            Tienda Registrada ({shop.name})
          </Button>
        );
      } else {
        return (
          <Button
            fullWidth
            type="submit"
            variant="contained"
            component={Link}
            to={routesConstants.shopSignUp}
            sx={{ marginBottom: 1 }}
          >
            Registrar mi negocio
          </Button>
        );
      }
    };
    return (
      <Container maxWidth="sm">
        <Paper
          sx={{
            paddingTop: 3,
            height: ' 100vh',
          }}
        >
          <Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Stack>
                <Typography
                  textAlign={'center'}
                  color={'primary'}
                  fontSize={22}
                >
                  Bienvenido,
                </Typography>
                <Typography
                  sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}
                  fontWeight={'bold'}
                  textAlign={'center'}
                  color={'primary'}
                  fontSize={25}
                >
                  {user.name}
                  <Button type="submit" component={Link} to={'/editar_perfil'}>
                    <EditIcon />
                  </Button>
                </Typography>
                <ShopButton />
                <Button variant="outlined" sx={{ width: 300 }} onClick={LogOut}>
                  Cerrar Sesión
                </Button>
              </Stack>
            </Box>

            <Box
              display={'flex'}
              justifyContent={'center'}
              sx={{ marginTop: 30 }}
            >
              <Paper elevation={0} sx={{ width: '100vw', maxWidth: '100%' }}>
                <MenuList>
                  <MenuItem
                    component={Link}
                    to={routesConstants.customerService}
                    onClick={() =>
                      AnalyticsManager.trackEvent(AnalyticsEvent.CLICK_SUPPORT)
                    }
                  >
                    <ListItemIcon sx={{ ml: 1 }}>
                      <SupportAgent fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Servicio al cliente</ListItemText>
                    <ListItemIcon>
                      <KeyboardArrowRight fontSize="small" />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={routesConstants.faq}
                    onClick={() =>
                      AnalyticsManager.trackEvent(AnalyticsEvent.CLICK_FAQ)
                    }
                  >
                    <ListItemIcon sx={{ ml: 1 }}>
                      <Help fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Preguntas frecuentes</ListItemText>
                    <ListItemIcon>
                      <KeyboardArrowRight fontSize="small" />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={routesConstants.aboutUs}
                    onClick={() =>
                      AnalyticsManager.trackEvent(AnalyticsEvent.CLICK_ABOUT_US)
                    }
                  >
                    <ListItemIcon sx={{ ml: 1 }}>
                      <People fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Acerca de nosotros</ListItemText>
                    <ListItemIcon>
                      <KeyboardArrowRight fontSize="small" />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem component={Link} to={routesConstants.deleteAccount}>
                    <ListItemIcon sx={{ ml: 1 }}>
                      <Build fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Eliminar cuenta</ListItemText>
                    <ListItemIcon>
                      <KeyboardArrowRight fontSize="small" />
                    </ListItemIcon>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Box>
            <Box display="flex" justifyContent="center" margin={2}>
              <Card
                style={{
                  borderRadius: 25,
                  padding: '3px',
                  backgroundColor: '#EF3D32',
                }}
              >
                <Box width={44} height={44} display="flex" alignItems="center">
                  <ElchaletLogo />
                </Box>
              </Card>
            </Box>
            <Box display="flex" justifyContent="center" margin={2}>
              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                <Box>
                  <img width={'30px'} src={ElSalvadorLogo} alt="" />
                </Box>
                <Typography>Hecho en El Salvador</Typography>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Container>
    );
  }
  if (!userId) {
    return <SingIn />;
  }
};
