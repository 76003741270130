import { Box, Modal, styled } from '@mui/material';
import React from 'react';
import { Login } from 'ui/pages/Auth/login';
import { Register } from 'ui/pages/Auth/register';

type props = {
  open: boolean;
  handleClose: () => void;
};

const BoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '0.5em',
  boxShadow: '0px 0px 50px 5px rgba(0, 0, 0, 0.5)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
}));

export const LoginModal: React.FC<props> = ({ open, handleClose }) => {
  const [showLogin, setShowLogin] = React.useState(true);
  const [showRegister, setShowRegister] = React.useState(false);

  const handleShowRegister = () => {
    setShowRegister(true);
    setShowLogin(false);
  };
  const handleShowLogin = () => {
    setShowRegister(false);
    setShowLogin(true);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxStyled>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {showLogin && <Login handleShowRegister={handleShowRegister} />}
          {showRegister && <Register handleShowLogin={handleShowLogin} />}
        </Box>
      </BoxStyled>
    </Modal>
  );
};
